// const submitterRegex = new RegExp('Submitter_([A-Za-z]+)')

const getRoles = user => {
    if (user && user.profile && user.profile.role) {
        const roles = user.profile.role
        if (Array.isArray(roles)) {
            const ng911roles = roles
                                .filter(x => x.startsWith("NG911_") || x === "SystemAdmin")
                                .map(x => x.replace("NG911_", ""))
            return ng911roles.join(',')
        } else {
            return roles
        }
    } else {
        return ''
    }
}

const checkRole = (role, roles) => {
    if (roles && role) {
        return roles.toLowerCase().includes(role.toLowerCase())
    } else {
        return false
    }
}

const hasApplicationAccess = user => {
    if (user && user.profile && user.profile.role) {
        const roles = user.profile.role
        if (Array.isArray(roles)) {
            const hasAccess = roles.some(x => x.includes('NG911') && x.includes('Allowed'))
            return hasAccess
        } else {
            return false
        }
    } else {
        return false
    }
}

const isAdmin = roles => checkRole('Admin', roles)

const isSysAdmin = roles => checkRole('SystemAdmin', roles)

export {
    getRoles,
    checkRole,
    isAdmin,
    isSysAdmin,
    hasApplicationAccess
}
