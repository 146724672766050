import React, { useContext, useState } from 'react'
import Modal from '../../elem/Modal'
import { PSAPDetailContext } from '../../wrappers/PSAPDetailContext'
import withConfig from '../../wrappers/withConfig'
import toast from '../../elem/Toast'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { UserContext } from '../../wrappers/UserContext'
import { isSysAdmin } from '../../../utils/user/permissions'

const ClearButton = ({ config }) => {
    const { PSAP } = useContext(PSAPDetailContext)
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { roles } = useContext(UserContext)
    const [showModal, setShowModal] = useState(false)
    const [alertMessage, setAlertMessage] = useState(null)
    const [alertHeader, setAlertHeader] = useState(null)

    const showMessage = (showModal, alertMessage, alertHeader) => {
        setAlertMessage(alertMessage)
        setAlertHeader(alertHeader)
        setShowModal(showModal)
    }

    const executeAction = () => {
        authenticatedFetch(`${API_URL}/upload/rollback/${PSAP.Id}`)
            .then(async (response) => {
                if (response.ok) {
                    showMessage(true, 'Removing PSAP data has been scheduled successfully. We will send an email about the result shortly.', 'Clear PSAP data')
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
                showMessage(true, `Failed to remove PSAP data.`, "Failed")
            })
    }

    return showModal ? (
        <Modal
            isShowing={showModal}
            className="downloads"
            toggle={() => setShowModal(false)}
            header={alertHeader}
            footer={
                <button
                    type="button"
                    onClick={() => setShowModal(false)}
                    className="button green"
                >
                    Ok
                </button>
            }
            body={<p>{alertMessage}</p>}
        />
    ) : (
        <>
            {isSysAdmin(roles) && (
                <div className="buttonWrapper">
                    <button className="button wg" onClick={executeAction}>
                        Clear PSAP Data
                    </button>
                </div>
            )}           
        </>
    )
}

export default withConfig(ClearButton)
