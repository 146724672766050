import React, { useState, useEffect } from 'react';
import withConfig from '../wrappers/withConfig'

const ToolkitVersion = ({ config }) => {
    const [version, setVersion] = useState('');
    const { API_URL } = config

    useEffect(() => {
        fetch(`${API_URL}/PSAP/ToolkitVersion`)
            .then(response => response.text())
            .then(data => {
                setVersion(data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [API_URL]);

    return (
        <div>
            <p>Current Version of Toolkit: {version}</p>
        </div>
    );
};

export default withConfig(ToolkitVersion);
