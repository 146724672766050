import React, { useContext } from 'react'

import withConfig from '../../wrappers/withConfig'
import toast from '../../elem/Toast'
import { APIRequestContext } from '../../wrappers/APIRequestContext'
import { PSAPContext } from '../../wrappers/PSAPContext'
import {Link} from 'react-router-dom'

const UploadActionButton = ({
    style,
    id,
    action,
    name,
    config,
    setShowModal,
}) => {
    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)
    const { setNewObject } = useContext(PSAPContext)
    
    const executeAction = (uploadId) => {
        setNewObject(false)
        authenticatedFetch(`${API_URL}/upload/${action}/${uploadId}`)
            .then(async (response) => {
                if (response.ok) {
                    setShowModal(true, `The ${action} action has been executed successfully.`, "Success")
                    setNewObject(true)
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
                setShowModal(true, `Failed to execute ${action} action.`, "Failed")
            })
    }

    return (
        <div className="linkCell downloads" style={style}>
            <Link
                className={`is-link is-size-7`}
                onClick={() => executeAction(id)}
                style={{textTransform: "capitalize"}}
            >
                {name}
            </Link>
        </div>
    )
}

export default withConfig(UploadActionButton)
