import React, { useContext, useState } from 'react'
import { saveAs } from 'file-saver'

import withConfig from '../wrappers/withConfig'
import toast from './Toast'
import Modal from './Modal'
import { APIRequestContext } from '../wrappers/APIRequestContext'
import { UserContext } from '../wrappers/UserContext'
import { isAdmin, isSysAdmin } from '../../utils/user/permissions'
import { Link } from 'react-router-dom'

import urls from '../../utils/constants/urls'

const NavBar = ({ config }) => {
    const [showModal, setShowModal] = useState(false)
    const [alertMessage] = useState(`Could not find file.`)
    const [alertHeader] = useState(`File Download Failed`)
    const { user, roles, client } = useContext(UserContext)
    const [active, setActive] = useState(false)

    const admin = isAdmin(roles)
    const sysadmin = isSysAdmin(roles)

    const { API_URL } = config
    const { authenticatedFetch } = useContext(APIRequestContext)

    const downloadPSAPs = () => {
        authenticatedFetch( `${API_URL}/PSAP/DownloadPSAPs`, {
            method: 'GET',
            headers: {
              'Content-Type': 'text/csv'
            }})
            .then(async (response) => {
                if (response.ok) {
                    return response.blob()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((data) => {
                saveAs(data, 'psap-list.csv')
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
                setShowModal(true)
            })
    }

    return (
        <>
            {showModal ? (
                <Modal
                    isShowing={showModal}
                    className="downloads"
                    toggle={() => setShowModal(false)}
                    header={alertHeader}
                    footer={
                        <button
                            type="button"
                            onClick={() => setShowModal(false)}
                            className="button green"
                        >
                            Ok
                        </button>
                    }
                    body={<p>{alertMessage}</p>}
                />
            ) : null}
            <nav className="navbar is-transparent has-text-light is-fixed-top">
                <div className="navbar-brand">
                    <Link to="/">
                        <h2 className="navbar-link is-arrowless is-size-4">
                            Oklahoma NG911 Data Processing
                        </h2>
                    </Link>
                    <a
                        role="button"
                        className={`navbar-burger ${active ? 'is-active' : ''}`}
                        onClick={() => {
                            setActive(!active)
                        }}
                        data-target="navMenu"
                        aria-label="menu"
                        aria-expanded="false"
                    >
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>

                <div className="navbar-end">
                    <div
                        id="navMenu"
                        className={`navbar-menu ${active ? 'is-active' : ''}`}
                    >
                        {user && (
                            <div className="navbar-item has-dropdown is-hoverable">
                                <h2 className="navbar-link is-size-6 is-arrowless">
                                    {user && user.profile && user.profile.name
                                        ? user.profile.name
                                        : 'User'}
                                </h2>
                                <div id="navbar" className="navbar-dropdown">
                                    <Link to="/" className="navbar-item">
                                        {/* <button
                                            onClick={(event) => {
                                                event.target.blur()
                                            }}
                                            className="navbar-item button wb small"
                                        > */}
                                            <h2>Your PSAPs</h2>
                                        {/* </button> */}
                                    </Link>
                                    {sysadmin && (
                                        // <button className="navbar-item clickable button wb small">
                                            <a
                                                className="navbar-item"
                                                onClick={downloadPSAPs}
                                            >
                                                <h2>Download PSAPs</h2>
                                            </a>
                                        // </button>
                                    )}
                                    {admin && (
                                        // <button className="navbar-item clickable button wb small">
                                            <a
                                                className="navbar-item"
                                                target="_blank"
                                                rel="opener"
                                                href={urls.manageUsers}
                                            >
                                                <h2>Admin</h2>
                                            </a>
                                        // </button>
                                    )}
                                    {/* <button className="navbar-item clickable button wb small"> */}
                                        <a
                                        
                                            target="_blank"
                                            className="navbar-item"

                                            rel="opener"
                                            href={urls.changePassword}
                                        >
                                            <h2>Change Password</h2>
                                            
                                        </a>
                                    {/* </button> */}
                                    <a
                                        // className="navbar-item clickable button wb small"
                                        className="navbar-item"
                                        onClick={function () {
                                            client.signoutRedirect()
                                        }}
                                    >
                                        <h2>Logout</h2>
                                        
                                    </a>

                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </>
    )
}

export default withConfig(NavBar)
